/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'previous_question_hover': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<defs><filter x="-10.4%" y="-10.4%" width="129.2%" height="129.2%" filterUnits="objectBoundingBox" id="svgicon_previous_question_hover_a"><feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.0784313725 0 0 0 0 0.0980392157 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1"/></filter><circle pid="0" id="svgicon_previous_question_hover_b" cx="12" cy="12" r="12"/></defs><g _fill="none" fill-rule="evenodd"><g transform="matrix(0 -1 -1 0 26 26)"><use _fill="#000" filter="url(#svgicon_previous_question_hover_a)" xlink:href="#svgicon_previous_question_hover_b"/><use _fill="#2D335B" xlink:href="#svgicon_previous_question_hover_b"/></g><path pid="1" d="M15 18.243l-4.066-4.066a.25.25 0 010-.354L15 9.757" _stroke="#F9F9F9" stroke-width="2" stroke-linecap="round"/></g>'
  }
})
